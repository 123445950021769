// extracted by mini-css-extract-plugin
export var loader = "Loader-module--loader--011ef";
export var opacityEnter = "Loader-module--opacityEnter--15db0";
export var opacityExit = "Loader-module--opacityExit--d1f71";
export var pulse = "Loader-module--pulse--8f4b2";
export var pulseAndHide = "Loader-module--pulseAndHide--87c74";
export var rollDown = "Loader-module--rollDown--fd6f0";
export var rollUp = "Loader-module--rollUp--53b8e";
export var slideInDown = "Loader-module--slideInDown--0cbd0";
export var slideOutUp = "Loader-module--slideOutUp--ad095";
export var splashEnter = "Loader-module--splashEnter--66c3e";
export var splashExit = "Loader-module--splashExit--c7065";