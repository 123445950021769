import { useState, useEffect } from 'react';
import { windowGlobal } from '@/base/utils/isWindowAvailable';
import { getCookie } from '../helpers/cookies';
import { queryParamsIncludesUTMValues, UTM_COOKIE_NAME } from '../helpers/utm';
import { readQueryParams } from '../helpers/queryParams';
import {
  sendGTMDropdownChanged,
  sendGTMFormStart,
  sendGTMFormSubmit,
  sendGTMFormValidationError,
  sendGTMTextFieldChanged,
} from '../helpers/gtm';
import { validateEmail, validatePhone } from '../helpers/validators';
import { hydrateInputsEvents, clearInputEvents } from '../utils/formFieldsEvents';
import { capitalizeFirstLetter } from '../helpers/strings';
import loadMarketoScript from '../scripts/loadMarketoScript';

type MarketoOptions = {
  formId: string;
  submitLabelOverride?: string;
  bypassForm?: boolean;
};

export default function useMarketoForm<T>({ formId, submitLabelOverride }: MarketoOptions, deps?: Array<T>) {
  if (!formId || !process.env.GATSBY_MARKETO_SOURCE)
    return { formLoaded: false, submitted: false, checkboxVisible: false };

  const [submitted, sendForm] = useState<boolean>(false);
  const [formLoaded, loadForm] = useState<boolean>(false);
  const [formStarted, setFormStarted] = useState<boolean>(false);
  const [checkboxVisible, changeCheckboxVisibility] = useState<boolean>(true);
  const [userSubscribed, setUserSubscription] = useState<boolean>(false);

  const [isScriptLoaded, setScriptLoaded] = useState(false);

  function changeStartForm() {
    if (formStarted) return null;
    return setFormStarted(true);
  }

  function formOnSuccess(form: MarketoForm) {
    const formValues = form.vals();
    form.getFormElem().hide();

    sendGTMFormSubmit(formId, formValues['Email']);
    sendForm(!submitted);
    return false;
  }

  function formOnLoad(form: MarketoForm) {
    form?.onSuccess(() => {
      return formOnSuccess(form);
    });

    loadForm(true);
  }

  function validateField(field: HTMLInputElement, formId: string) {
    if (field.ariaRequired && !field.value) sendGTMFormValidationError(field.name, 'This field is required', formId);
    if (field.name?.toLowerCase().includes('email') && !validateEmail(field.value))
      sendGTMFormValidationError(field.name, 'Email has a wrong format', formId);
    if (field.name?.toLowerCase().includes('phone') && !validatePhone(field.value))
      sendGTMFormValidationError(field.name, 'Phone has a wrong format', formId);
  }

  function prefillInputFields(prefillData: Record<string, string>, form: MarketoForm) {
    if (!prefillData || prefillData.internal_error_message) return null;

    const capitalizedPrefillData = Object.keys(prefillData).reduce((prevState, currentState) => {
      const inputName = currentState.includes('form') ? currentState : capitalizeFirstLetter(currentState);
      return {
        ...(prevState || {}),
        [inputName]: prefillData[currentState],
      };
    }, {});

    if (!prefillData.unsubscribed && prefillData.explicitOptin) {
      setUserSubscription(true);
      changeCheckboxVisibility(false);
    }

    form.vals(capitalizedPrefillData);
  }

  function prefillInputUTMFields(prefillData: Record<string, string> | null, form: MarketoForm) {
    if (!queryParamsIncludesUTMValues(prefillData || {})) {
      const utmCookie = getCookie(UTM_COOKIE_NAME);
      const parsedUTMCookie = utmCookie ? JSON.parse(utmCookie) : null;

      if (!parsedUTMCookie) return null;

      Object.entries(parsedUTMCookie).forEach(([utmKey, utmValue]) => {
        form.vals({ [utmKey]: utmValue });
      });
    }
  }

  function checkPrefilledCheckboxVisibility() {
    const countryInput = document.querySelector('#Country') as HTMLSelectElement;
    if (countryInput?.value === 'United States') return changeCheckboxVisibility(false);
  }

  function formWhenReady(form: MarketoForm) {
    if (!windowGlobal) return null;
    const params = readQueryParams();
    const cookie = getCookie('_mkto_trk');
    const wrapper = document.querySelector('#marketoForm');

    console.log('Marketo flag', process.env.GATSBY_MARKETO_PREFILL_ACTIVE === 'true');
    if (cookie && process.env.GATSBY_MARKETO_PREFILL_ACTIVE === 'true') {
      fetch('/.netlify/functions/marketo-prefill', {
        method: 'POST',
        body: cookie,
      })
        .then(async response => {
          if (response.status !== 200) {
            console.log('_mkto_trk cookie doesn’t exist');
            return;
          }
          return response.text();
        })
        .then(parsedResponse => (parsedResponse ? prefillInputFields(JSON.parse(parsedResponse), form) : {}));
    }
    hydrateInputsEvents(changeStartForm, { wrapper, buttonLabel: submitLabelOverride });
    checkPrefilledCheckboxVisibility();
    prefillInputUTMFields(params, form);
  }

  function checkCountryListener(e: Event) {
    if ((e.target as HTMLInputElement).id !== 'Country') return null;
    // if ((e.target as HTMLInputElement)?.value === 'United States' || userSubscribed)
    //   return changeCheckboxVisibility(false);

    return changeCheckboxVisibility(true);
  }

  function changeValueListener(e: Event) {
    const target = e.target as HTMLInputElement;

    validateField(target, formId);

    if (target.type === 'text' || target.type === 'email' || target.type === 'tel')
      return sendGTMTextFieldChanged(target?.id, formId);
    if (target.type === 'select-one') return sendGTMDropdownChanged(target.id, target?.value, formId);
  }

  useEffect(() => {
    if (!document.getElementById('marketo-js')) {
      loadMarketoScript(() => setScriptLoaded(true));
    } else {
      setScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isScriptLoaded) {
      MktoForms2.loadForm(process.env.GATSBY_MARKETO_SOURCE!, process.env.GATSBY_MUNCHKIN_ID, formId, formOnLoad);
      MktoForms2.whenReady(formWhenReady);
    }
  }, [...(deps || []), formId, isScriptLoaded]);

  useEffect(() => {
    const wrapper = document.querySelector('#marketoForm');
    wrapper?.addEventListener('change', checkCountryListener);
    return () => wrapper?.removeEventListener('change', checkCountryListener);
  }, [userSubscribed]);

  useEffect(() => {
    const wrapper = document.querySelector('#marketoForm');
    wrapper?.addEventListener('change', changeValueListener);
    return () => {
      wrapper?.removeEventListener('change', changeValueListener);
      clearInputEvents(wrapper);
    };
  }, []);

  useEffect(() => {
    if (formStarted) sendGTMFormStart(formId);
  }, [formStarted]);

  return {
    formLoaded,
    submitted,
    checkboxVisible,
  };
}
