// extracted by mini-css-extract-plugin
export var aside = "ContactHero-module--aside--3d834";
export var asideWrapper = "ContactHero-module--asideWrapper--be233";
export var bodyWrapper = "ContactHero-module--bodyWrapper--4609c";
export var bold = "ContactHero-module--bold--40748";
export var childrenWrapper = "ContactHero-module--childrenWrapper--15115";
export var content = "ContactHero-module--content--ca669";
export var desktopSidebar = "ContactHero-module--desktopSidebar--21186";
export var fadeOut = "ContactHero-module--fadeOut--fc3de";
export var formWrapper = "ContactHero-module--formWrapper--3886d";
export var headingH1 = "ContactHero-module--headingH1--0cfdf";
export var headingH2 = "ContactHero-module--headingH2--f3787";
export var link = "ContactHero-module--link--c3bd4";
export var list = "ContactHero-module--list--5b843";
export var listItem = "ContactHero-module--listItem--1c52f";
export var loader = "ContactHero-module--loader--a2395";
export var mobileSidebar = "ContactHero-module--mobileSidebar--21439";
export var opacityEnter = "ContactHero-module--opacityEnter--2a735";
export var opacityExit = "ContactHero-module--opacityExit--f5425";
export var paragraph = "ContactHero-module--paragraph--82549";
export var rollDown = "ContactHero-module--rollDown--1065b";
export var rollUp = "ContactHero-module--rollUp--cfb22";
export var root = "ContactHero-module--root--e4b12";
export var slideInDown = "ContactHero-module--slideInDown--93d5f";
export var slideOutUp = "ContactHero-module--slideOutUp--06e5b";
export var splashEnter = "ContactHero-module--splashEnter--226fe";
export var splashExit = "ContactHero-module--splashExit--c685b";
export var thankYouWrapper = "ContactHero-module--thankYouWrapper--27152";
export var wrapper = "ContactHero-module--wrapper--2dc19";