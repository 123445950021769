export default function loadMarketoScript(onLoad: (...args: Array<unknown>) => void) {
  if (!process.env.GATSBY_MARKETO_SCRIPT_URL) return null;
  const s = document.createElement('script');
  s.id = 'marketo-js';
  s.type = 'text/javascript';
  s.async = true;
  s.src = process.env.GATSBY_MARKETO_SCRIPT_URL;
  // @ts-ignore
  s.onreadystatechange = function () {
    // @ts-ignore
    if (this.readyState === 'complete' || this.readyState === 'loaded') {
      onLoad(true);
    }
  };
  s.onload = () => onLoad(true);
  document.getElementsByTagName('head')[0].appendChild(s);
}
