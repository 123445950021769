// extracted by mini-css-extract-plugin
export var headingH3 = "ThankYouMessage-module--headingH3--060d5";
export var iconWrapper = "ThankYouMessage-module--iconWrapper--c2d25";
export var opacityEnter = "ThankYouMessage-module--opacityEnter--27a7f";
export var opacityExit = "ThankYouMessage-module--opacityExit--a9e49";
export var paragraph = "ThankYouMessage-module--paragraph--1e9d3";
export var rollDown = "ThankYouMessage-module--rollDown--6d337";
export var rollUp = "ThankYouMessage-module--rollUp--542ad";
export var root = "ThankYouMessage-module--root--2481b";
export var slideInDown = "ThankYouMessage-module--slideInDown--4263e";
export var slideOutUp = "ThankYouMessage-module--slideOutUp--519e1";
export var splashEnter = "ThankYouMessage-module--splashEnter--f9c63";
export var splashExit = "ThankYouMessage-module--splashExit--86073";
export var wrapper = "ThankYouMessage-module--wrapper--6ff89";