type HydrateFormOptions = {
  wrapper?: Element | null;
  buttonLabel?: string;
};

let hydrationInterval: NodeJS.Timer;

function inputOnFocusIn(node: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) {
  const nodeLabel = node.parentNode?.querySelector('label');
  return nodeLabel?.classList.add('mktoFocus');
}

function inputOnFocusOut(node: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) {
  const nodeLabel = node.parentNode?.querySelector('label');

  if (!node.value) nodeLabel?.classList.remove('mktoFocus');
}

function revalidateSelectLabels(wrapper: Element | null) {
  if (!wrapper) return null;
  const selectFields = wrapper?.querySelectorAll('select');

  selectFields.forEach(select => {
    const selectLabel = select.parentNode?.querySelector('label');
    selectLabel?.classList.add('mktoFocus');

    const options = select.querySelectorAll('option');
    options.forEach(option => {
      if (!option.value) option.value = ' ';
    });
  });
}

function normalizeChcekboxesMargin(inputs: HTMLInputElement[]) {
  const checkboxesOnly = inputs.filter(element => element.type === 'checkbox');

  checkboxesOnly.forEach(checkbox => {
    const isRequired = checkbox?.ariaRequired === 'true';

    if (isRequired) {
      checkbox.parentElement?.parentElement?.classList.add('requiredCheckbox');
    } else {
      checkbox.parentElement?.classList.add('notRequiredCheckbox');
      checkbox.parentElement?.parentElement?.parentElement?.classList.add('emptyBottomMargin');
    }
  });
}

function getFormInputs(wrapper?: Element | null) {
  const inputFields: NodeListOf<HTMLInputElement> | undefined = wrapper?.querySelectorAll('input');
  const selectFields: NodeListOf<HTMLSelectElement> | undefined = wrapper?.querySelectorAll('select');
  const textareaFields: NodeListOf<HTMLTextAreaElement> | undefined = wrapper?.querySelectorAll('textarea');

  const inputs = [...(inputFields || []), ...(selectFields || []), ...(textareaFields || [])];

  return {
    inputFields,
    selectFields,
    textareaFields,
    inputs,
  };
}

export function hydrateInputsEvents(gtmStartEvent: () => void, { wrapper, buttonLabel }: HydrateFormOptions) {
  if (!wrapper) return null;

  const { selectFields, textareaFields, inputs } = getFormInputs(wrapper);
  const submitButton: HTMLButtonElement | null = wrapper?.querySelector('button');

  revalidateSelectLabels(wrapper);
  normalizeChcekboxesMargin(inputs as HTMLInputElement[]);

  if (submitButton && buttonLabel) {
    submitButton.innerText = buttonLabel;
  }

  inputs.forEach(input => {
    input.addEventListener('change', () => {
      gtmStartEvent();
      inputOnFocusIn(input);
    });
    input.addEventListener('focusin', () => inputOnFocusIn(input));
    input.addEventListener('focusout', () => inputOnFocusOut(input));

    if (input.value.length > 0 && input.type !== 'hidden')
      input.parentElement?.querySelector('label')?.classList.add('mktoFocus');
  });

  selectFields?.forEach(select => {
    select.addEventListener('change', () => revalidateSelectLabels(wrapper));
  });

  textareaFields?.forEach(textarea => {
    textarea.parentElement?.classList.add('mktoTextareaWrap');
  });

  hydrationInterval = setInterval(() => {
    const { inputs } = getFormInputs(wrapper);

    inputs.forEach(input => {
      if (input.type !== 'hidden' && input.value.length > 1 && !input.classList.contains('mktoFocus'))
        inputOnFocusIn(input);
    });
  }, 1000);
}

export function clearInputEvents(wrapper: Element | null) {
  const { selectFields, inputs } = getFormInputs(wrapper);

  inputs.forEach(input => {
    input.removeEventListener('change', () => inputOnFocusIn(input));
    input.removeEventListener('focusin', () => inputOnFocusIn(input));
    input.removeEventListener('focusout', () => inputOnFocusOut(input));
  });

  selectFields?.forEach(select => {
    select.removeEventListener('change', () => revalidateSelectLabels(wrapper));
  });

  clearInterval(hydrationInterval);
}
