import React from 'react';
import withLayout from '@/base/containers/withLayout';
import ContactHero from '@/components/sections/ContactHero';
import loadable from '@loadable/component';
import { ContactPageTemplateProps, ContactPageTypes } from './ContactPage.types';

const OfficeLocations = loadable(() => import('../../sections/OfficeLocations'));

export function ContactPage({ content }: StoryblokPage<ContactPageTypes>) {
  const { form_id, hero_body, office_locations, title, button_label, thank_you_message } = content;

  return (
    <div>
      <ContactHero formId={form_id} body={hero_body} buttonLabel={button_label} onSubmitBody={thank_you_message} />
      <OfficeLocations officeLocations={office_locations} title={title} />
    </div>
  );
}

export default function ContactPageTemplate({ pageContext, location }: ContactPageTemplateProps) {
  return withLayout(ContactPage, { ...pageContext, location });
}
