import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import useMarketoForm from '@/base/hooks/useMarketoForm';
import { BREAKPOINTS } from '@/base/consts/breakpoints';
import SectionRichText from '@/components/sections/SectionRichText/SectionRichText';
import { ThankYouMessage } from '@/components/modules/ThankYouMessage/ThankYouMessage';
import Loader from '@/components/modules/Loader';
import type { ContactHeroTypes } from './ContactHero.types';
import * as styles from './ContactHero.module.scss';

export default function ContactHero({ body, buttonLabel, formId, onSubmitBody }: ContactHeroTypes) {
  const isTablet = useMediaQuery({ query: BREAKPOINTS.LG });
  const formWrapperRef = useRef<HTMLDivElement>(null);

  const { checkboxVisible, submitted, formLoaded } = useMarketoForm({ formId, submitLabelOverride: buttonLabel }, [
    isTablet,
  ]);

  if (!body) return null;

  useEffect(() => {
    if (formWrapperRef && formWrapperRef.current) {
      if (submitted && isTablet) {
        formWrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [submitted]);

  function renderSidebar() {
    return (
      <aside className={styles.asideWrapper}>
        <div className={styles.aside}>
          <div id="marketoForm" ref={formWrapperRef} className={styles.formWrapper}>
            {!submitted && (
              <form
                id={`mktoForm_${formId}`}
                className="mktoContact"
                data-checkbox-visibility={String(checkboxVisible)}
                data-visible={String(!submitted)}
              />
            )}
            <Loader visible={!formLoaded} />
            <ThankYouMessage body={onSubmitBody} visibility={submitted} />
          </div>
        </div>
      </aside>
    );
  }

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {body && (
            <div className={styles.bodyWrapper}>
              <SectionRichText body={body} className={styles} />
            </div>
          )}
          {!isTablet && (
            <div className={styles.desktopSidebar}>
              <div className={styles.childrenWrapper}>{renderSidebar()}</div>
            </div>
          )}
        </div>
      </div>
      {isTablet && <div className={styles.mobileSidebar}>{renderSidebar()}</div>}
    </div>
  );
}
