import React, { PropsWithChildren } from 'react';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import { ReactComponent as Checkmark } from '@/assets/icons/checkmarkBlue.svg';
import SectionRichText from '@/components/sections/SectionRichText';
import * as styles from './ThankYouMessage.module.scss';

export function ThankYouMessage({
  body,
  visibility,
  children,
}: PropsWithChildren<{ body?: StoryblokRichtext; visibility: boolean }>) {
  if (!body) return null;

  return (
    <div className={styles.wrapper} data-visible={String(visibility)}>
      <div className={styles.iconWrapper}>
        <Checkmark />
      </div>
      {body && <SectionRichText body={body} className={styles} />}
      {children}
    </div>
  );
}
